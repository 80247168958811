const heroSwiper = new Swiper('.hero-swiper', {
  loop: true,
  slidesPerView: 1,

  navigation: {
    nextEl: '.hero-swiper-button-next',
    prevEl: '.hero-swiper-button-prev',
  },
});

const reviewsSwiper = new Swiper('.reviews-swiper', {
  loop: true,
  autoplay: {
    delay: 5000,
    disableOnInteraction: false,
  },

  breakpoints: {
    576: {
      slidesPerView: 1,
    },
    992: {
      slidesPerView: 2,
      spaceBetween: 40,
    },
    1200: {
      slidesPerView: 3,
      spaceBetween: 20,
    },
  }
});

window.addEventListener('DOMContentLoaded', () => {
  const openDialog = () => {
    const btnsOpen = document.querySelectorAll('.btn-order');
    const dialog = document.querySelector('.dialog');
    const dialogCloses = document.querySelectorAll('.dialog-close');
    const overlays = document.querySelectorAll('.overlay');

    btnsOpen.forEach(btn => {
      btn.addEventListener('click', () => {
        dialog.classList.add('is-active');
        overlays.forEach(overlay => overlay.classList.add('is-active'));
      })
    })
    dialogCloses.forEach(dialogClose => {
      dialogClose.addEventListener('click', () => {
        dialog.classList.remove('is-active');
        overlays.forEach(overlay => overlay.classList.remove('is-active'));
      })
    })
    overlays.forEach(overlay => {
      overlay.addEventListener('click', () => {
        dialog.classList.remove('is-active');
        overlay.classList.remove('is-active');
      })
    })
  }
  openDialog();

  const openNavbar = () => {
    const burger = document.querySelector('.header-burger');
    const navbar = document.querySelector('.navbar');

    burger.addEventListener('click', () => {
      burger.classList.toggle('is-open');
      navbar.classList.toggle('is-open');
    })
  }
  openNavbar();

  const sendForm = () => {
    const forms = document.querySelectorAll('.form');

    forms.forEach(form => {
      form.addEventListener('submit', e => {
        e.preventDefault();
        location.replace("/thanks.html");
      })
    })
  }
  sendForm();

  const checkCookies = () => {
    let cookieDate = localStorage.getItem('cookieDate');
    let cookie = document.querySelector('.cookie_notification');

    if (!cookieDate || (+cookieDate + 31536000000) < Date.now()) {
      cookie.classList.add('is-show');
    }

    cookie.querySelector('.cookie_notification__btn').addEventListener('click', () => {
      localStorage.setItem('cookieDate', Date.now());
      cookie.classList.remove('is-show');
    })
  }
  checkCookies();

  const renameEmail = () => {
    const emailListElements = document.querySelectorAll(".emailList");// Найти все элементы с классом "emailList"
    const hostname = window.location.hostname;// Получить текущий домен
    emailListElements.forEach(function(element) {// Пройти по каждому найденному элементу
      element.setAttribute("href", "mailto:support@" + hostname);// Записать в атрибут href
      element.innerHTML = "support@" + hostname;// Записать в тег
    });
  }
  renameEmail();
})